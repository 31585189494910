initListeners=function(){
  $(window).scroll(function(event) {
    valorScroll=$(window).scrollTop();

    // añade clase "enpantalla" cuando aparece en pantalla
    $(".js-aparece-scroll").each(function(index, el) {
      if ($(this).offset().top+$(this).height()<=valorScroll+$(window).height()){
        $(this).addClass("enpantalla");
      }else{
        $(this).removeClass("enpantalla");
      }
    });

    if($("body").hasClass("centros")){
      if(valorScroll>330){
        $(".encabezado").addClass("fixed");
        $(".volver").addClass("fixed");
        //$(".vista").addClass("fixed");
        //$("#listado").addClass("fixed");
        //$("#mapa").addClass("fixed");
      }else{
        $(".encabezado").removeClass("fixed");
        $(".volver").removeClass("fixed");
        //$(".vista").removeClass("fixed");
        //$("#listado").removeClass("fixed");
        //$("#mapa").removeClass("fixed");
        
      }
    }

  });

  $('[data-toggle="tooltip"]').tooltip();
  
  /**
   * LISTENER validador formularios
   */
    /*$('.js-form-validator').validator(customValidations()).on('submit', function (e) {
      if ($(this).data('enviar-ajax') == undefined || $(this).data('enviar-ajax') == true){
            enviar_por_ajax = true;
      }else{
            enviar_por_ajax = false;
      }
      if (e.isDefaultPrevented() ) {
        $(this).find('.has-error').each(function(index, element){
          console.log("hay errores");
        });
      }
      else{
        //console.log('pasa');
          if ($(this).data('spinner-holder') != ''){
            spinner_holder = $($(this).data('spinner-holder')).find('.js-spinner-holder');
          }else{
            $('body').find('.js-spinner-holder');
          }
          spinner_holder.fadeIn();

          // Si hay que enviarlo por ajax ejecutamos simpleAjaxForm, si no, dejamos que haga el submit
          $(this).find("button[type='submit']").addClass('btn-loader');
          if (enviar_por_ajax){
            console.log('enviar ajax');
            if ($(this).data('archivos')){
              //console.log('ini');
                $(this).simpleAjaxFormFiles();
            }
            else{
                $(this).simpleAjaxForm();
            }    
                e.preventDefault();
          }

      } 
    });*/

    $('.js-form-validator').validator(customValidations()).on('submit', function (e) {
      $('.alert').hide();
      //console.log('oculta1:'+$(this).attr('id'));
      
      if ($(this).data('enviar-ajax') == undefined || $(this).data('enviar-ajax') == true){
            enviar_por_ajax = true;
      }else{
            enviar_por_ajax = false;
      }

      if (e.isDefaultPrevented() ) {
       
            $('.chosen-select').each(function(){
                element = $(this);
                id_chosen = element.attr('id');
                if (element.val() == ''){
                  $('#error_'+id_chosen).addClass('has-error');
                  $('#error_'+id_chosen).removeClass('has-success');
                }
                else{
                  $('#error_'+id_chosen).addClass('has-success');
                  $('#error_'+id_chosen).removeClass('has-error');
                }
            }); 
            lista_errores = "";
            $(this).find('.has-error').each(function(index, element){
              if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
                lista_errores += '<li>'+$(this).attr('data-mensaje-error')+'</li>'; 
              }else{
                //lista_errores += '<li>'+$(this).find('label').html()+'</li>';            
              }
            });

            if (lista_errores !=''){
                lista_errores = '<ul>'+lista_errores+'</ul>';
            }
            

            // handle the invalid form...
           

            // handle the invalid form...
           // console.log("****"+$(this).attr("class"));
            alerta = $(this).data('alerta');
            //console.log("###"+$(this).data('scroll-modal'))
            if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
                //$('#myModal').mostrar_msg("¡Lo sentimos!", $(this).data('mensaje-error')+lista_errores);
                $('#'+alerta).find('span').html($(this).attr('data-mensaje-error')+lista_errores);
                $('#'+alerta).show();
                if ($(this).data('scroll-modal')=="1") {
                  TweenMax.to($("#modal-registro"), 1, {scrollTo:{y:200,autoKill:false},delay:0,ease:Power3.easeInOut});
                }else{
                  PLG_scrollTo(1,$('#'+alerta),0,-120);
                }
                if ($(this).attr('id') == 'descargar-bono1'){
                  TweenMax.to(jQuery("#modal-descargabono"), 1, {scrollTo:{y:jQuery("#modal-descargabono").offset().top+150,autoKill:false},delay:0,ease:Power3.easeInOut});
                }
            }else{
                //$('#myModal').mostrar_msg("¡Lo sentimos!", "Revisa todos los datos antes de continuar"+lista_errores);              
                $('#'+alerta).find('span').html("Revise todos los datos antes de continuar"+lista_errores);
                $('#'+alerta).show();
                if ($(this).data('scroll-modal')=="1") {
                  TweenMax.to($("#modal-registro"), 1, {scrollTo:{y:200,autoKill:false},delay:0,ease:Power3.easeInOut});
                }else{
                  PLG_scrollTo(1,$('#'+alerta),0,-120);
                }
                if ($(this).attr('id') == 'descargar-bono1'){
                  TweenMax.to(jQuery("#modal-descargabono"), 1, {scrollTo:{y:jQuery("#modal-descargabono").offset().top+150,autoKill:false},delay:0,ease:Power3.easeInOut});
                }                
            }


      }
      else{
          if ($(this).data('spinner-holder') != ''){
            spinner_holder = $($(this).data('spinner-holder')).find('.js-spinner-holder');
          }else{
            $('body').find('.js-spinner-holder');
          }
          spinner_holder.fadeIn();

          // Si hay que enviarlo por ajax ejecutamos simpleAjaxForm, si no, dejamos que haga el submit
          $('.alert').hide();
               // console.log('oculta2');

          $(this).find("button[type='submit']").addClass('loader');
          //$(this).find("button[type='submit']").prop('disabled', true);
          //console.log('poner loader');
          if (enviar_por_ajax){
            //console.log('enviar ajax');
            if ($(this).data('archivos')){
              //console.log('ini');
                $(this).simpleAjaxFormFiles();
            }
            else{
              //console.log('ini2');
                $(this).simpleAjaxForm();
              //console.log('fin');  
            }    
                e.preventDefault();
          }

      } 
      
    });
    

  /**
   * LISTENER para mostrar/ocultar objetos
   */
  $(".js-toggle-div").click(function(){
    var obj=$(this).data("toggle-div");
    $(obj).toggleClass("desactivo");
  })

  /**
   * LISTENER menus con ancla
   */
  $(".js-menu-ancla").click(function(e){
    e.preventDefault();
    var ancla=$(this).attr("href");
    var offset=$(this).data("menuancla-offset");
    if(offset=="" || offset==undefined){
      offset=0;
    }
    PLG_scrollTo(1,ancla,0,offset,0);
  })

  /**
   * LISTENER para despliegue menú hamburguesa
   */
  $('#menu_desplegable').on('show.bs.collapse', function () {
    PLG_showMenu();
    PLG_closeModalLogin();
  });
  $("#menu_desplegable .close").click(function(){
    PLG_closeMenu();
  });

  /**
   * LISTENER para mostrar modal login
   */
  $(".js-btn-header-login").click(function(){
    if($("#menu_desplegable").hasClass("show")){
      PLG_closeMenu(function(){
         PLG_toggleModalLogin();
      });
    }else{
      PLG_toggleModalLogin();
    }
  });

  /**
   * LISTENER para mostrar modal alerta
   */
  $(".js-modal-alerta").click(function(){
    PLG_showModalAlerta($(this).data("alerta-titulo"),$(this).data("alerta-texto"));
  });

  /**
   * LISTENER cierre cookies
   */
  $("#modal-cookies .close").click(function(){
    $("#modal-cookies").fadeOut();
    setCookie("tlcpromo-cookies-tecnica", "true", 365);
  });
  checkCookie();

  /**
   * LISTENER copiar de un input
   */
  $(".js-btn-copy").click(function() {
      var target=$(this).data("target-copy");
      copyToClipboard(document.getElementById(target));
  }); 

  $(".cont-user-menu .close").click(function(){
    $("#dropdownMenu2").trigger("click");
  });

/**
 * funcion parallax de movimiento raton
 * [necesita clase js-parallaxElement]
 * [atributo data-movimientopxy] -> para indicar velocidad Y
 * [atributo data-movimientopxx] -> para indicar velocidad X
 */
  // $( document ).mousemove( function( e ) {
  //   //variables globales
  //   ancho=$(window).width()/2;
  //   alto=$(window).height()/2;
  //   posx=e.clientX-ancho;
  //   posy=e.clientY-alto;
  //   pctx=parseInt(posx*100/ancho,10);
  //   pcty=parseInt(posy*100/alto,10);

  //   $('.js-parallaxMouse').each(function(index, el){
  //     tempx=pctx*parseInt($(el).attr("data-movimientopxx"),12)/100*-1;
  //     tempy=pcty*parseInt($(el).attr("data-movimientopxy"),12)/100*-1;
  //     $(el).css({
  //       '-ms-transform': 'translate('+tempx+'px, '+tempy+'px)',
  //       '-webkit-transform': 'translate('+tempx+'px, '+tempy+'px)',
  //       'transform': 'translate('+tempx+'px, '+tempy+'px)'
  //     });
  //   });
  //   $(".js-alphaElement").each(function(index, el) {
  //     if ($(window).scrollTop() < parseInt($(this).attr("data-topescroll"),10)){
  //       pct=e.clientX*100/$(window).width();
  //       $(el).find("img").css({
  //         'opacity': pct/100,
  //       });
  //     }
  //   });
  // });


/**
 * funcion parallax de movimiento scroll
 * [necesita clase js-parallaxScroll]
 * [atributo data-movimientopxy] -> para indicar velocidad Y
 */
  // $( window ).scroll( function( e ) {
  //   //variables globales
  //   alto=$(window).height()/2;
  //   posy=$(window).scrollTop();
  //   pcty=parseInt(posy*100/alto,10);

  //   $('.js-parallaxScroll').each(function(index, el){
  //     tempy=pcty*parseInt($(el).attr("data-movimientopxy"),12)/100*-1;
  //     $(el).css({
  //       '-ms-transform': 'translateY('+tempy+'px)',
  //       '-webkit-transform': 'translateY('+tempy+'px)',
  //       'transform': 'translateY('+tempy+'px)'
  //     });
  //   });
  // });

};


